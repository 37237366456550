$(document).ready(function() {
    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    var owl = $('.owl-carousel');

    owl.owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        items: 1,
        autoplay:true,
        autoplayTimeout:5000
    });

    /* START menu for tablets and mobiles */
    $("#head a.mobile-btn").click(function() {
        if($('#head .nav').hasClass('active')){
            $('#head .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-menu').removeClass('icon-close');
        }else{
            $('#head .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-menu').addClass('icon-close');
        }
    });

    $("a.acord-btn").click(function() {
        $(this).closest('.btn-group').find('a').removeClass('active');
        $(this).addClass('active');
        $('.'+$(this).attr('data-acrods')).hide();
        $('#'+$(this).attr('data-acrod-target')).show();
    });

    $('.modal a.close-modal').click(function(){
        $($(this).attr('data-target')).removeClass('active');
    });
});
$(function () {
     $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();
 } );

function initMap() {
    // Styles a map in night mode.
    var map = new google.maps.Map(document.getElementById('googleMap'), {
        center: {
            lat: 49.7707350,
            lng: 13.3627267
        },
        zoom: 16,
        disableDefaultUI: false,
        scrollwheel: false,
        styles: [{

                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [{
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [{
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [{
                    "visibility": "off"
                }]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [{
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [{
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ]
    });
    var image = 'http://www.idesigns.cz/html/stask/images/mapPin.png';
    var myLatlng = new google.maps.LatLng(49.7707350, 13.3627267);
    var marker = new google.maps.Marker({
        position: myLatlng,
        animation: google.maps.Animation.DROP,
        title: "Hello World!",
        size: new google.maps.Size(101, 138),
        icon: image
    });
    marker.setMap(map);
}
